(function($) {
    'use strict';
    var preloaded = [];
    function preload(images) {
        for (var i = 0; i < images.length; i++) {
            preloaded[i] = new Image();
            preloaded[i].src = images[i];
        }
    }

    var $slides = $('[data-slides]');
    $($slides).each(function (i,slider) {
        slider = $(slider);
        var images = slider.data('slides');
        if(images.length) {
            preload(images);
            var count = images.length;
            var current = 0;
            var slideshow = function(isFirstRun) {
                current++;
                if(current >= count) {
                    current = 0;
                }
                var newimage = images[current];
                console.log("SLIDE!");
                slider.css('background-image', 'url("' + newimage + '")');
                setTimeout(slideshow, 5000);
            };
            slideshow();

        }
    });
}(jQuery));
var myLazyLoad = new LazyLoad({
    elements_selector: "[data-src]"
});

jQuery(function($) {
    $('.hamburger').on('click', function () {
        $('.hamburgermenu').toggleClass('slided');
        $('main').toggleClass('slideleft');
        $('.trigger').toggleClass('crossed');
        $('body').toggleClass('noscroll');
    });

    $('video').videoSrcset({
        resize: true,
        resizeDelay: 50
    });


});

